import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageTickerComponent } from './message-ticker.component';





@NgModule({
  declarations: [
    MessageTickerComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    MessageTickerComponent
  ]
})
export class MessageTickerModule { }

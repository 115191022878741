import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Observable} from "rxjs";
import {map, startWith, tap} from "rxjs/operators";
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import icClose from '@iconify/icons-ic/twotone-close';
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";

@Component({
  selector: 'vought-app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit {

  autocompleteHTML =
    `<mat-input-container>
  <mat-label>State</mat-label>
  <input type="text" matInput [matAutocomplete]="auto" [formControl]="stateCtrl">
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let option of options" [value]="option">
      {{ option }}
    </mat-option>
  </mat-autocomplete>
</mat-input-container>
`;


  filteredStates$: Observable<any[]>;
  @Input() stateCtrl: FormControl;
  @Input() states: Observable<any[]>;
  @Input() title: string
  @Output() stateEvent = new EventEmitter<FormControl>();

  get icClose(): any {
    return icClose;
  }

  get icArrowDropDown(): any {
    return icArrowDropDown;
  }

  ngOnInit() {
    if (!this.stateCtrl || !this.stateCtrl.value) {
      this.stateCtrl = new FormControl();
    }
    this.states.pipe(tap(states => {
      if (states) {
        this.filteredStates$ = this.stateCtrl.valueChanges.pipe(
          startWith(''),
          map(state => state ? this.filterStates(state, states) : states.slice())
        );
      }
    })).subscribe();

  }

  filterStates(name: string, states: any[]) {
    return states.filter(state =>
      state.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  onChange($event: MatAutocompleteSelectedEvent) {
    this.stateEvent.emit(this.stateCtrl)
  }
}

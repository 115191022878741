<div class="card overflow-hidden">
  <div (click)="eventAction.emit(element.id)"
       class="p-4 text-center hover:bg-hover trans-ease-out cursor-pointer relative"
       matRipple>
    <h2 class="title mb-1 mt-3">{{ element?.contactName }}</h2>
    <div class="body-2 text-secondary">
      <ic-icon [icon]="icBusiness" class="ltr:mr-1 rtl:ml-1" inline="true"></ic-icon>
      <span>{{ element?.name }}</span>
    </div>

    <div *ngIf="element.phoneNumber" class="body-2 text-secondary">
      <ic-icon [icon]="icPhone" class="ltr:mr-1 rtl:ml-1" inline="true"></ic-icon>
      <span>{{ element?.phoneNumber }}</span>
    </div>

    <div *ngIf="!element.phoneNumber" class="body-2 text-secondary">
      <ic-icon *ngIf="!element.phoneNumber" [icon]="icDescription" class="ltr:mr-1 rtl:ml-1" inline="true"></ic-icon>
      <span>{{ element?.description }} </span>
    </div>

    <button (click)="emitToggleStar($event)" class="absolute top-2 right-2" mat-icon-button type="button">
      <mat-icon *ngIf="element?.starred" [icIcon]="icStar" class="text-amber"></mat-icon>
      <mat-icon *ngIf="!element?.starred" [icIcon]="icStarBorder"></mat-icon>
    </button>

    <!-- New icon/button to indicate tenant status -->
    <button *ngIf="element?.enable==false" class="absolute top-2 left-2" mat-icon-button type="button" matTooltip="Entreprise disactiver"
    (click)="doEnable(element)" >
      <mat-icon  [icIcon]="icDisabled"></mat-icon>
    </button>
  </div>

  <div class="bg-app-bar p-2" fxLayout="row" fxLayoutAlign="space-around center">
    <button class="text-secondary" mat-icon-button type="button" *ngFor="let action of actions" [matTooltip]="action.label"
            (click)="doAction(action,element)">
      <mat-icon [icIcon]="action.icon"></mat-icon>
    </button>
  </div>
</div>

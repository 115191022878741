import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef} from '@angular/core';
import {FormControl} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {PopoverService} from "../../../../../@vex/components/popover/popover.service";
import {Kanbanboard} from "../../interfaces/kanban.interface";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {KanbanboardList} from "../../interfaces/kanban-list.interface";
import {KanbanboardCard} from "../../interfaces/kanban-card.interface";
import icStar from '@iconify/icons-ic/twotone-star';
import icStarBorder from '@iconify/icons-ic/twotone-star-border';
import icNotifications from '@iconify/icons-ic/twotone-notifications';
import {Observable, of} from "rxjs";
import {stagger80ms} from "./animations/stagger.animation";
import {fadeInUp400ms} from "./animations/fade-in-up.animation";
import {trackById} from "../../utils/track-by";
import {ActionEnum, DataService} from "../../services/data.service";

@Component({
  selector: 'vought-app-kanban-board',
  templateUrl: './kanban-board.component.html',
  styleUrls: ['./kanban-board.component.scss'],
  animations: [
    stagger80ms,
    fadeInUp400ms
  ]
})
export class KanbanBoardComponent implements OnInit, OnChanges {

  static nextId = 100;
  @Input() kanbanboards: any[];
  @Input() title: string;
  @Input() actions: any[]
  @Output() actionEvent = new EventEmitter<any>();

  board$: Observable<any>;

  addCardCtrl = new FormControl();
  addListCtrl = new FormControl();

  trackById = trackById;
  private icNotifications = icNotifications;
  private icStar = icStar;
  private icStarBorder = icStarBorder;


  constructor(private dialog: MatDialog,
              private route: ActivatedRoute,
              private popover: PopoverService,
              private dataService: DataService) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.board$ = of(this.kanbanboards.find(board => board.id === 1));
  }

  ngOnInit() {
    this.board$ = of(this.kanbanboards.find(board => board.id === 1));
  }

  open(board: Kanbanboard, list: KanbanboardList, card: KanbanboardCard) {
    this.addCardCtrl.setValue(null);
    this.actionEvent.emit({
      width: '700px',
      maxWidth: '100%',
      disableClose: true,
      data: {card, list, board},
      action: "detail"
    });
  }

  drop(event: CdkDragDrop<KanbanboardCard[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);

      this.actionEvent.emit({
        id: event.container.id,
        data: event.item.data,
        action: "drop"
      })
    }
  }

  dropList(event: CdkDragDrop<KanbanboardList[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  getConnectedList(board: Kanbanboard) {
    return board.children.map(x => `${x.id}`);
  }

  openAddCard(list: KanbanboardList, content: TemplateRef<any>, origin: HTMLElement) {
    this.popover.open({
      content,
      origin,
      position: [
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        },
      ]
    });
  }

  openAddList(board: Kanbanboard, content: TemplateRef<any>, origin: HTMLElement) {
    this.popover.open({
      content,
      origin,
      position: [
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top'
        },
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        },
      ]
    });
  }

  createCard(list: KanbanboardList, close: () => void) {
    if (!this.addCardCtrl.value) {
      return;
    }

    list.children.push({
      id: KanbanBoardComponent.nextId++,
      title: this.addCardCtrl.value
    });

    close();

    this.addCardCtrl.setValue(null);
  }


  toggleStar(board: Kanbanboard) {
    board.starred = !board.starred;
  }

  doAction(action: any, details: any) {
    const item = JSON.parse(details);
    this.actionEvent.emit({
      data: item,
      action: action.action
    });
  }

}

import {Icon} from "@visurel/iconify-angular";
import {ActionEnum} from "../services/data.service";

export interface Action {
  label: string,
  icon: Icon,
  action: ActionEnum,
  list: boolean,
  grid: boolean,
  kanban: boolean,
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SharedRoutingModule} from './shared-routing.module';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {HttpClientModule} from "@angular/common/http";
import {MatInputModule} from "@angular/material/input";

import {AlertComponent} from "./components/alert/alert.component";
import {LabelsComponent} from './components/labels/labels.component';
import {MatNativeDateModule, MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {IconModule} from "@visurel/iconify-angular";
import {ChipsInputComponent} from './components/chips-input/chips-input.component';
import {MatChipsModule} from "@angular/material/chips";
import {MatIconModule} from "@angular/material/icon";
import {UiModule} from "@vought-app/ui";
import {DecimalNumberDirective} from "./directives/decimal-number/decimal-number.directive";
import {MatDividerModule} from "@angular/material/divider";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {FlexLayoutModule} from "@angular/flex-layout";
import {PageLayoutModule} from "../../../../../libs/@vex/components/page-layout/page-layout.module";
import {ContainerModule} from "../../../../../libs/@vex/directives/container/container.module";
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
//import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatTooltipModule} from "@angular/material/tooltip";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { ToastAlertComponent } from './components/toast-alert/toast-alert.component';


@NgModule({
  declarations: [
    /** Components**/
    AlertComponent,
    LabelsComponent,
    ChipsInputComponent,
    ConfirmDialogComponent,
    ToastAlertComponent,



    /** Directives**/
    DecimalNumberDirective,


  ],
  imports: [
    /** CORE **/
    CommonModule,
    ReactiveFormsModule,
    SharedRoutingModule,
    HttpClientModule,
    UiModule,
    IconModule,
    FlexLayoutModule,
    FormsModule,
    PageLayoutModule,
    ContainerModule,

    /** Material**/
    MatButtonToggleModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatChipsModule,
    MatIconModule,
    MatDividerModule,
    MatDialogModule,
    MatMenuModule,
    MatButtonModule,
    /*MatDatepickerModule,*/
    MatPaginatorModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatInputModule,
    MatTableModule,

  ],
  exports: [
    /** Modules**/
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    UiModule,
    IconModule,
    FlexLayoutModule,
    FormsModule,
    PageLayoutModule,
    ContainerModule,

    /** Material**/
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatMenuModule,
    MatSelectModule,
    MatTooltipModule,
    MatOptionModule,
    MatChipsModule,
    MatDividerModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,



    /** Components**/
    AlertComponent,
    ToastAlertComponent,
    LabelsComponent,
    ChipsInputComponent,
    ConfirmDialogComponent,

    /** Directives**/
    DecimalNumberDirective
  ]
})
export class SharedModule {
}

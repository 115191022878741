<mat-form-field class="flex-auto  chip">
  <mat-label>{{title}}</mat-label>
  <mat-chip-list #chipList aria-label="Element selection">
    <mat-chip *ngFor="let element of elements" [selectable]="selectable"
              [removable]="removable" (removed)="remove(element)">
      {{element.name}}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input [placeholder]="placeholder"
           [matChipInputFor]="chipList"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           [matChipInputAddOnBlur]="addOnBlur"
           (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>
</mat-form-field>

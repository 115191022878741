import { Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { ConfigService } from "../../../../libs/@vex/services/config.service";
import { Style, StyleService } from "../../../../libs/@vex/services/style.service";
import { Platform } from "@angular/cdk/platform";
import { LayoutService } from "../../../../libs/@vex/services/layout.service";
import { ActivatedRoute } from "@angular/router";
import { NavigationService } from "../../../../libs/@vex/services/navigation.service";
import { SplashScreenService } from "../../../../libs/@vex/services/splash-screen.service";
import { Settings } from "luxon";
import { DOCUMENT } from "@angular/common";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { filter, map } from "rxjs/operators";
import { ConfigName } from "../../../../libs/@vex/interfaces/config-name.model";
import icShoppingCart from '@iconify/icons-ic/twotone-shopping-cart';
import icAccountBalance from '@iconify/icons-ic/twotone-account-balance';
import icPeople from '@iconify/icons-ic/people';
import icWarehouse from '@iconify/icons-ic/twotone-warehouse';
import icMessage from '@iconify/icons-ic/message';
import icTodo from '@iconify/icons-ic/twotone-assignment';
import { RolesEnum } from './shared/enums/roles.enum';

@Component({
  selector: 'vought-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'vought-app';

  constructor(private configService: ConfigService,
    private styleService: StyleService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService) {
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    showConfigButton: false,
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.pipe(
      map(queryParamMap => queryParamMap.has('rtl') && coerceBooleanProperty(queryParamMap.get('rtl'))),
    ).subscribe(isRtl => {
      this.document.body.dir = isRtl ? 'rtl' : 'ltr';
      this.configService.updateConfig({
        rtl: isRtl
      });
    });

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('layout'))
    ).subscribe(queryParamMap => this.configService.setConfig(queryParamMap.get('layout') as ConfigName));

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.styleService.setStyle(queryParamMap.get('style') as Style));


    this.navigationService.items = [
      {
        type: 'link',
        label: 'Accueil',
        route: 'home',
        icon: icAccountBalance,
        permissions: ['COM', RolesEnum.SUPER_ADMIN, RolesEnum.ADMIN, RolesEnum.FINANCIER, RolesEnum.PORTAGE_EMPLOYEE, RolesEnum.REGULAR_EMPLOYEE],
      },
      {
        type: 'link',
        label: 'Admin',
        route: 'admin',
        icon: icWarehouse,
        permissions: ['COM', RolesEnum.SUPER_ADMIN],
      },
      {
        type: 'dropdown',
        label: 'Clients',
        icon: icPeople,
        permissions: ['COM', RolesEnum.SUPER_ADMIN, RolesEnum.ADMIN, RolesEnum.FINANCIER],

        children: [
          {
            type: 'link',
            label: 'import des clients',
            permissions: ['COM', RolesEnum.ADMIN, RolesEnum.FINANCIER],
            route: 'customers/import',
          },
          {
            type: 'link',
            label: 'Mes clients',
            permissions: ['COM', RolesEnum.SUPER_ADMIN, RolesEnum.ADMIN, RolesEnum.FINANCIER],
            route: 'customers',
          },
        ]
      },



      {
        type: 'dropdown',
        label: 'Chiffre d\'affaires',
        icon: icAccountBalance,
        permissions: ['COM', RolesEnum.SUPER_ADMIN, RolesEnum.ADMIN, RolesEnum.FINANCIER],

        children: [
          {
            type: 'link',
            label: 'import de données',
            permissions: ['COM', RolesEnum.ADMIN, RolesEnum.FINANCIER],
            route: 'turnover/import',
          },
          {
            type: 'link',
            label: 'Liste des factures',
            permissions: [RolesEnum.SUPER_ADMIN, RolesEnum.ADMIN, RolesEnum.FINANCIER],
            route: 'turnover/list',
          }
        ]
      },
      {
        type: 'dropdown',
        label: 'Gestion des dépenses',
        icon: icShoppingCart,
        permissions: ['COM', RolesEnum.SUPER_ADMIN, RolesEnum.ADMIN, RolesEnum.FINANCIER, RolesEnum.PORTAGE_EMPLOYEE, RolesEnum.REGULAR_EMPLOYEE],
        children: [
          {
            type: 'link',
            label: 'ajouter une dépense',
            permissions: ['COM', RolesEnum.PORTAGE_EMPLOYEE, RolesEnum.REGULAR_EMPLOYEE],
            route: 'expense/add-expense'
          },
          {
            type: 'link',
            label: 'Catégories',
            permissions: ['COM', RolesEnum.SUPER_ADMIN, RolesEnum.ADMIN, RolesEnum.FINANCIER],
            route: 'expense/categories'
          },
          {
            type: 'link',
            label: 'import des dépenses',
            permissions: ['COM', RolesEnum.ADMIN, RolesEnum.FINANCIER],
            route: 'expense/import'
          },
          {
            type: 'link',
            label: 'Historique',
            permissions: ['COM', RolesEnum.PORTAGE_EMPLOYEE, RolesEnum.REGULAR_EMPLOYEE],
            route: 'expense/history'
          },
          {
            type: 'link',
            label: 'Demandes',
            permissions: ['COM', RolesEnum.SUPER_ADMIN, RolesEnum.ADMIN, RolesEnum.FINANCIER],
            route: 'expense/requests'
          }
        ]



      },
      {
        type: 'link',
        label: 'Messages',
        route: 'messages',
        icon: icMessage,
        permissions: ['COM', RolesEnum.ADMIN],
      },

      {
        type: 'dropdown',
        label: 'taches',
        icon: icTodo,
        permissions: ['COM', RolesEnum.ADMIN, RolesEnum.FINANCIER, RolesEnum.PORTAGE_EMPLOYEE, RolesEnum.REGULAR_EMPLOYEE],
        children: [
          {
            type: 'link',
            label: 'Taches Board',
            route: 'tasks/board',
            icon: icTodo,
            permissions: ['COM', RolesEnum.ADMIN, RolesEnum.FINANCIER, RolesEnum.PORTAGE_EMPLOYEE, RolesEnum.REGULAR_EMPLOYEE],
          },
          {
            type: 'link',
            label: 'Taches Liste',
            route: 'tasks/list',
            icon: icTodo,
            permissions: ['COM', RolesEnum.ADMIN, RolesEnum.FINANCIER, RolesEnum.PORTAGE_EMPLOYEE, RolesEnum.REGULAR_EMPLOYEE],
          }
        ]
      }


    ];
  }

}
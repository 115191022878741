import {DateTime} from 'luxon';
import {KanbanboardLabel} from './kanban-label.interface';

export interface KanbanboardCard {
  id: number;
  title: string;
  description?: string;
  deadlineDate?: DateTime;
  customer?: any;
  priority?: KanbanboardLabel;
  labels?: KanbanboardLabel[];
  details?: string;
}

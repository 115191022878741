<div class="h-full" fxLayout="column">
  <div class="p-6 pb-0 bg-card shadow-b" fxFlex="none">
    <div vexContainer>
      <h1 class="display-1 mt-0 mb-4" fxLayout="row" fxLayoutAlign="start center">
      <span @scaleIn
            class="w-12 h-12 rounded-full text-primary ltr:mr-4 rtl:ml-4 flex items-center justify-center bg-primary-light">
      <ic-icon [icon]="icPeople" size="24px"></ic-icon>
      </span>
        <span @fadeInRight class="block">{{title}}</span>
      </h1>

      <div fxLayout="row" fxLayoutAlign="start center">
        <nav class="vex-tabs border-0" fxFlex="auto" mat-tab-nav-bar>
          <a #rla="routerLinkActive"
             *ngFor="let link of links"
             [active]="rla.isActive"
             [disabled]="link.disabled"
             (click)="filterData(link.value)"
             mat-tab-link
             routerLinkActive>
            <mat-icon [icIcon]="link.icon"></mat-icon>
            {{ link.label }}
          </a>

        </nav>

        <div class="bg-card rounded-full border px-4"
             fxFlex="300px"
             fxFlex.lt-md="auto"
             fxHide.xs
             fxLayout="row"
             fxLayoutAlign="start center">
          <ic-icon [icIcon]="icSearch" size="20px" color="primary"></ic-icon>
          <input [formControl]="searchCtrl"
                 class="px-4 py-3 border-0 outline-none w-full bg-transparent"
                 placeholder="Search..."
                 type="search">
        </div>


        <button (click)="addElement()"
                class="ltr:ml-4 rtl:mr-4"
                color="primary"
                fxFlex="none"
                fxHide.xs
                mat-raised-button
                type="button">
          <ic-icon [icon]="icPersonAdd"
                   class="ltr:mr-2 rtl:ml-2 ltr:-ml-1 rtl:-mr-1"
                   inline="true"
                   size="20px"></ic-icon>
          <span>{{addMessage}}</span>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="filteredElements"
       @stagger
       class="overflow-y-auto"
       fxFlex="auto">
    <div class="p-gutter"
         vexContainer
         gdColumns="1fr 1fr 1fr 1fr"
         gdColumns.lt-md="1fr 1fr"
         gdColumns.xs="1fr"
         gdGap="24px">
      <vought-app-element-card (eventAction)="handleEvent($event)"
                            (toggleStar)="toggleStar($event)"
                            [actions]="actions"
                            *ngFor="let element of filteredElements; trackBy: trackById"
                            @fadeInUp
                            [element]="element"></vought-app-element-card>
    </div>
  </div>

  <div *ngIf="!filteredElements"
       @scaleFadeIn
       fxFlex="auto"
       fxLayout="column"
       fxLayoutAlign="center center">
    <img class="m-12 h-64" src="assets/img/illustrations/idea.svg">
    <h2 class="headline m-0 text-center">Aucun enregistement correspondant à vos filtres</h2>
  </div>
</div>


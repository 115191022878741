import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MessageModel } from '../model/message.model';
import { Pageable } from '../../../shared/interfaces/pageable';
import { TableParams } from '../../../shared/interfaces/table_params';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MessageState {
  private messagesSubject$ = new BehaviorSubject<Pageable<MessageModel>>(null);
  private activeMessagesSubject$ = new BehaviorSubject<MessageModel[]>([]);
  private messagePageableTable: TableParams = {
    page: 0,
    size: 10,
    sort: '',
    sortDirection: ''
  };

  private mapSortField(sortField: string): string {
    const sortMapping = {
      senderName: 'sender.name',
      recipientName: 'recipient.name'
    };
    return sortMapping[sortField] || sortField;
  }

  private setHttpParam(params: HttpParams, key: string, value: any): HttpParams {
    return value ? params.set(key, value.toString()) : params;
  }

  getTableParamsMessages(): HttpParams {
    if (!this.messagePageableTable) {
      return new HttpParams();
    }

    const { size, page, sort, sortDirection } = this.messagePageableTable;
    const mappedSort = this.mapSortField(sort);

    let params = new HttpParams();
    params = this.setHttpParam(params, 'size', size);
    params = this.setHttpParam(params, 'page', page);
    params = this.setHttpParam(params, 'sort', mappedSort ? `${mappedSort},${sortDirection}` : null);

    return params;
  }

  setTableParamsMessage(data: any) {
    this.messagePageableTable = data;
  }

  getMessages$(): Observable<Pageable<MessageModel>> {
    return this.messagesSubject$.asObservable();
  }

  getActiveMessages$(): Observable<MessageModel[]> {
    return this.activeMessagesSubject$.asObservable();
  }

  setMessages(messages: Pageable<MessageModel>): void {
    this.messagesSubject$.next(messages);
  }

  setActiveMessages(messages: MessageModel[]): void {
    this.activeMessagesSubject$.next(messages);
  }

  addMessage(message: MessageModel): void {
    const currentPageable = this.messagesSubject$.getValue();
    currentPageable.content.push(message);
    this.messagesSubject$.next({ ...currentPageable });
    
  }

  updateMessage(updatedMessage: MessageModel): void {
    const currentMessages = this.messagesSubject$.getValue();
    const updatedMessages = currentMessages.content.map((message) => {
      return message.id === updatedMessage.id ? updatedMessage : message;
    });
    this.messagesSubject$.next({
      ...currentMessages,
      content: updatedMessages
    });
  }

  removeMessage(id: string): void {
      const currentMessages = this.messagesSubject$.getValue();
      const updatedMessages = currentMessages.content.filter((message) => message.id !== id);
      this.messagesSubject$.next({
        ...currentMessages,
        content: [...updatedMessages] 
      });
    
    console.log(this.messagesSubject$, id);
  }
}
import {Injectable} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {Observable, Subject} from "rxjs";
import {NavigationStart, Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  message;
  cssClass;

  private subject = new Subject<any>();

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.clear();
      }
    });
  }

  getAlert(): Observable<any> {
    return this.subject.asObservable();
  }

  success(message: string) {
    this.subject.next({type: 'success', text: message});
  }

  error(message: string) {
    this.subject.next({type: 'error', text: message});
  }

  clear() {
    this.subject.next();
  }
}
